html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

body {
    color: #333;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px
}

h1 {
    font-size: 42px;
    padding-top: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
    line-height: .9
}

h2 {
    font-size: 30px;
    margin: 20px 0 10px 0;
    line-height: .9
}

h3 {
    font-size: 24px;
    margin-bottom: 10px
}

a {
    color: #428bca;
    text-decoration: none
}

p {
    margin-bottom: 10px
}

hr {
    border: 0;
    border-top: 1px solid #eee;
    clear: both
}

cite {
    padding-left: 20px;
    font-size: 14px
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.25
}

ul {
    padding-left: 10px;
    list-style-type: disc
}

form {
    padding: 1%
}

@media(min-width:768px) {
    form {
        width: 50%
    }
}

.submitted,
#subscriptionSubmitted,
#contactSubmitted {
    padding: 10px;
    display: none;
    color: white;
    text-align: center
}

.container {
    min-height: 100%;
    max-width: 1140px;
    padding: 0 10px;
    margin: auto;
    background: white
}

.icons {
    text-align: center
}

.form-group {
    margin-bottom: 15px
}

.form-group label {
    width: 100%;
    float: left;
    margin-right: 10px;
    display: inline-block
}

.form-group input,
.form-group textarea {
    width: 100%;
    display: inline-block;
    box-sizing: border-box
}

.adsbygoogle {
    display: block;
    margin: 0 auto;
    clear: both
}

.area {
    display: block;
    float: left;
    width: 96%;
    margin: 1% 0 1% 0;
    padding: 2%;
    text-align: center;
    color: white
}

#katas {
    background: #259b24;
    background: linear-gradient(to bottom, #259b24 0, #2baf2b 100%);
    box-shadow: 3px 3px 5px 0 #259b24
}

#learn {
    background: #673ab7;
    background: linear-gradient(to bottom, #673ab7 0, #7e57c2 100%);
    box-shadow: 3px 3px 5px 0 #673ab7
}

#about {
    background: #e51c23;
    background: linear-gradient(to bottom, #e51c23 0, #e84e40 100%);
    box-shadow: 3px 3px 5px 0 #e51c23
}

#contact {
    background: #03a9f4;
    background: linear-gradient(to bottom, #03a9f4 0, #29b6f6 100%);
    box-shadow: 3px 3px 5px 0 #03a9f4
}

@media(min-width:768px) {
    .area {
        width: 45.2%;
        margin: 1% 0 1% 1.6%;
        min-height: 207px
    }
    .area:nth-child(2n+1) {
        margin-left: 0
    }
}

.kata {
    display: block;
    float: left;
    width: 100%;
    margin: 1% 0 1% 0
}

.kata img {
    border: 1px solid #DDD;
    float: left;
    margin-right: 10px
}

.kata h2,
.kata p {
    margin: 10px;
    color: #333
}

#fizzbuzz-kata {
    box-shadow: 3px 3px 5px 0 #ff6a00, -3px -3px 5px 0 #ff00dc
}

#romannumerals-kata {
    box-shadow: 3px 3px 5px 0 #0637a4, -3px -3px 5px 0 #ef5d06
}

#rockpaperscissors-kata {
    box-shadow: 3px 3px 5px 0 #2094a7, -3px -3px 5px 0 #d9d9e5
}

#berlinclock-kata {
    box-shadow: 3px 3px 5px 0 #ffce00, -3px -3px 5px 0 #000
}

#tennis-kata {
    box-shadow: 3px 3px 5px 0 #609b2f, -3px -3px 5px 0 #d7c80b
}

#snakesandladders-kata {
    box-shadow: 3px 3px 5px 0 #7bc242, -3px -3px 5px 0 #f8901e
}

#tamagotchi-kata {
    box-shadow: 3px 3px 5px 0 #a10000, -3px -3px 5px 0 #d852ad
}

#bingo-kata {
    box-shadow: 3px 3px 5px 0 #fda54b, -3px -3px 5px 0 #b12f1f
}

#connectfour-kata {
    box-shadow: 3px 3px 5px 0 #be8e00, -3px -3px 5px 0 #ae0000
}

@media(min-width:768px) {
    .kata {
        width: 49.2%;
        margin: 1% 0 1% 1.6%
    }
    .kata:nth-child(2n+1) {
        margin-left: 0
    }
}

#subscriptionSubmitted {
    background: #673ab7
}

.description {
    float: left;
    display: block;
    width: 100%;
    margin-bottom: 1%
}

.description img {
    margin-right: 1%
}

.features,
.feature,
.row {
    float: left;
    width: 100%
}

.card {
    float: left;
    width: 100%;
    margin: 1% 0 1% 0;
    outline: solid thin grey
}

.card h3 {
    margin-bottom: 0;
    padding: 2%;
    color: #fff;
    background: #2baf2b
}

.card p {
    padding: 2%;
    margin: 0
}

.card ul {
    padding: 0 0 0 6%;
    background: #eee
}

.card li {
    padding: 1%
}

.card .test-case-link {
    text-align: right;
    border-top: 1px solid grey;
    padding: 1%;
    background: #eee
}

.card .test-cases {
    display: block;
}

.card .test-cases table {
    width: 100%;
    text-align: center;
    border-collapse: collapse
}

.card .test-cases td {
    border: 1px solid #999
}

.card .test-cases th {
    font-weight: bold;
    border: 1px solid #999
}

.test-cases table {
    white-space: pre;
    line-height: normal;
    font-family: monospace
}

@media(min-width:768px) {
    .card {
        width: 48%;
        margin: 1% 0 1% 1.6%
    }
}

#contactSubmitted {
    background: #03a9f4
}

#navigation {
    background: white
}

#navigation #pull {
    display: block;
    text-align: right;
    font-size: 26px;
    padding: 4%
}

#navigation ul {
    padding: 0;
    display: none
}

#navigation li {
    position: relative;
    display: block;
    float: left;
    width: 100%
}

#navigation li h1 {
    font-size: 26px;
    margin: 0
}

#navigation li a {
    display: block;
    padding: 15px;
    color: black;
    text-decoration: none;
    font-size: 20px
}

.home {
    border-bottom: 5px solid #ff9800
}

.home .active {
    background: #ff9800
}

.katas {
    border-bottom: 5px solid #259b24
}

.katas .active {
    background: #259b24
}

.about {
    border-bottom: 5px solid #e51c23
}

.about .active {
    background: #e51c23
}

.contact {
    border-bottom: 5px solid #03a9f4
}

.contact .active {
    background: #03a9f4
}

.learn {
    border-bottom: 5px solid #673ab7
}

.learn .active {
    background: #673ab7
}

.active a {
    color: white !important
}

@media(min-width:768px) {
    #navigation {
        overflow: auto;
        width: 100%
    }
    #navigation #pull {
        display: none
    }
    #navigation ul {
        display: block
    }
    #navigation li {
        position: relative;
        display: block;
        float: left;
        width: auto
    }
    #navigation li h1 {
        font-size: 26px;
        margin: 0
    }
    #navigation li a {
        display: block;
        padding: 15px;
        color: black;
        text-decoration: none;
        font-size: 20px
    }
}

.rest {
    float: right
}
